import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/users/customers/overview`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/users/customers/add`} component={lazy(() => import(`./customers/new-customer`))} />
        <Route path={`${APP_PREFIX_PATH}/users/casemed-staff/overview`} component={lazy(() => import(`./casemedstaff`))} />
        <Route path={`${APP_PREFIX_PATH}/users/health-facility-staff/overview`} component={lazy(() => import(`./healthfacilitystaff`))} />
        <Route path={`${APP_PREFIX_PATH}/users/service-agents/overview`} component={lazy(() => import(`./serviceagents`))} />
        <Route path={`${APP_PREFIX_PATH}/pricing`} component={lazy(() => import(`./pricing`))} />
        <Route path={`${APP_PREFIX_PATH}/users/casemed-staff/add`} component={lazy(() => import(`./casemedstaff/newcasemedstaff`))} />
        <Route path={`${APP_PREFIX_PATH}/users/health-facility-staff/add`} component={lazy(() => import(`./healthfacilitystaff/newfacilitystaff`))} />
        <Route path={`${APP_PREFIX_PATH}/users/srvice-agents/add`} component={lazy(() => import(`./serviceagents/new-service-agent`))} />
        <Route path={`${APP_PREFIX_PATH}/cart`} component={lazy(() => import(`./cart`))} />
        <Route path={`${APP_PREFIX_PATH}/user/profile`} component={lazy(() => import(`./user-account/user-settings`))} />
        <Route path={`${APP_PREFIX_PATH}/user/settings`} component={lazy(() => import(`./user-account/user-settings`))} />
        <Route path={`${APP_PREFIX_PATH}/plans/overview`} component={lazy(() => import(`./plans`))} />
        <Route path={`${APP_PREFIX_PATH}/plans/add`} component={lazy(() => import(`./plans/new-plan`))} />
        <Route path={`${APP_PREFIX_PATH}/plan-types/overview`} component={lazy(() => import(`./plan-types`))} />
        <Route path={`${APP_PREFIX_PATH}/plan-types/add`} component={lazy(() => import(`./plan-types/new-plan-type`))} />
        <Route path={`${APP_PREFIX_PATH}/plan-covers/overview`} component={lazy(() => import(`./plan-covers`))} />
        <Route path={`${APP_PREFIX_PATH}/plan-covers/add`} component={lazy(() => import(`./plan-covers/new-plan-cover`))} />
        <Route path={`${APP_PREFIX_PATH}/cover-types/overview`} component={lazy(() => import(`./cover-types`))} />
        <Route path={`${APP_PREFIX_PATH}/cover-types/add`} component={lazy(() => import(`./cover-types/new-cover-type`))} />
        <Route path={`${APP_PREFIX_PATH}/facilities/overview`} component={lazy(() => import(`./facilities`))} />
        <Route path={`${APP_PREFIX_PATH}/facilities/add`} component={lazy(() => import(`./facilities/new-facility`))} />
        <Route path={`${APP_PREFIX_PATH}/plan-payments`} component={lazy(() => import(`./plan-payments`))} />
        <Route path={`${APP_PREFIX_PATH}/customer-transactions`} component={lazy(() => import(`./transactions`))} />
        <Route path={`${APP_PREFIX_PATH}/new-individual`} component={lazy(() => import(`./individual`))} />
        <Route path={`${APP_PREFIX_PATH}/new-medical-declaration`} component={lazy(() => import(`./medical-declaration`))} />
        <Route path={`${APP_PREFIX_PATH}/new-family`} component={lazy(() => import(`./family/new-family`))} />
        <Route path={`${APP_PREFIX_PATH}/onboard-overview-family`} component={lazy(() => import(`./family/onboard-overview`))} />
        <Route path={`${APP_PREFIX_PATH}/new-family-member`} component={lazy(() => import(`./family/new-family-member`))} />
        <Route path={`${APP_PREFIX_PATH}/new-group`} component={lazy(() => import(`./group/new-group`))} />
        <Route path={`${APP_PREFIX_PATH}/group-data-received`} component={lazy(() => import(`./group/data-recieved`))} />
        <Route path={`${APP_PREFIX_PATH}/new-group-member`} component={lazy(() => import(`./group/new-group-member`))} />
        <Route path={`${APP_PREFIX_PATH}/onboard-overview-group`} component={lazy(() => import(`./group/onboard-overview`))} />
        <Route path={`${APP_PREFIX_PATH}/verification-payment-ind`} component={lazy(() => import(`./waiting-payment/individual`))} />
        <Route path={`${APP_PREFIX_PATH}/verification-payment-fam`} component={lazy(() => import(`./waiting-payment/family`))} />
        <Route path={`${APP_PREFIX_PATH}/verification-payment-grp`} component={lazy(() => import(`./waiting-payment/group`))} />
        <Route path={`${APP_PREFIX_PATH}/plans-pending-verification/individual`} component={lazy(() => import(`./verification-list/individual`))} />
        <Route path={`${APP_PREFIX_PATH}/plans-pending-verification/family`} component={lazy(() => import(`./verification-list/family`))} />
        <Route path={`${APP_PREFIX_PATH}/plans-pending-verification/group`} component={lazy(() => import(`./verification-list/group`))} />
        <Route path={`${APP_PREFIX_PATH}/customer-individual-plan-details`} component={lazy(() => import(`./pending-plan-details/individual`))} />
        <Route path={`${APP_PREFIX_PATH}/customer-family-plan-details`} component={lazy(() => import(`./pending-plan-details/family`))} />
        <Route path={`${APP_PREFIX_PATH}/customer-group-plan-details`} component={lazy(() => import(`./pending-plan-details/group`))} />
        <Route path={`${APP_PREFIX_PATH}/payment-status`} component={lazy(() => import(`./payment-success`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);