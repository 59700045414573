import { 
  DashboardOutlined,
  DollarOutlined,
  UserOutlined,
  IdcardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const userRole = localStorage.getItem("userRole")

let dashBoardNavTree = []

if( userRole === "CUSTOMER"){
  dashBoardNavTree = [
    {
      key: 'home',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'HOME',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'pricing',
      path: `${APP_PREFIX_PATH}/pricing`,
      title: 'PRICING',
      icon: DollarOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]

}
else if ( userRole === "INSURANCE STAFF"){
  dashBoardNavTree = [
    {
      key: 'home',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'HOME',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'pricing',
      path: `${APP_PREFIX_PATH}/pricing`,
      title: 'PRICING',
      icon: DollarOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'USERS',
      icon: UserOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'customers',
          path: `${APP_PREFIX_PATH}/users/customers`,
          title: 'CUSTOMERS',
          icon: UserOutlined,
          breadcrumb: true,
          submenu: [
            {
              key: 'customers-overview',
              path: `${APP_PREFIX_PATH}/users/customers/overview`,
              title: 'OVERVIEW',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
            {
              key: 'add-customers',
              path: `${APP_PREFIX_PATH}/users/customers/add`,
              title: 'ADD CUSTOMER',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
          ]
        },
        
        {
          key: 'casemed-staff',
          path: `${APP_PREFIX_PATH}/users/casemed-staff`,
          title: 'CASEMED STAFF',
          icon: UserOutlined,
          breadcrumb: false,
          submenu: [
            {
              key: 'casemed-staff-overview',
              path: `${APP_PREFIX_PATH}/users/casemed-staff/overview`,
              title: 'OVERVIEW',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
            {
              key: 'add-casemed-staff',
              path: `${APP_PREFIX_PATH}/users/casemed-staff/add`,
              title: 'ADD CASEMED STAFF',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
          ]
        },
  
        {
          key: 'health-facility-staff',
          path: `${APP_PREFIX_PATH}/users/health-facility-staff`,
          title: 'HEALTH FACILITY STAFF',
          icon: UserOutlined,
          breadcrumb: true,
          submenu: [
            {
              key: 'health-facility-staff-overview',
              path: `${APP_PREFIX_PATH}/users/health-facility-staff/overview`,
              title: 'OVERVIEW',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
            {
              key: 'health-facility-staff-staff',
              path: `${APP_PREFIX_PATH}/users/health-facility-staff/add`,
              title: 'ADD HEALTH FACILITY STAFF',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
          ]
        },
  
        {
          key: 'service agents',
          path: `${APP_PREFIX_PATH}/users/service-agents`,
          title: 'SERVICE AGENTS',
          icon: UserOutlined,
          breadcrumb: true,
          submenu: [
            {
              key: 'service-agents-overvew',
              path: `${APP_PREFIX_PATH}/users/service-agents/overview`,
              title: 'OVERVIEW',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
            {
              key: 'add-service-agent',
              path: `${APP_PREFIX_PATH}/users/srvice-agents/add`,
              title: 'ADD SERVICE AGENT',
              icon: UserOutlined,
              breadcrumb: true,
              submenu: []
            },
          ]
        }
      ]
    },
  
    {
      key: 'plans',
      path: `${APP_PREFIX_PATH}/plans`,
      title: 'PLANS',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'plans-overview',
          path: `${APP_PREFIX_PATH}/plans/overview`,
          title: 'OVERVIEW',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'add-new-plan',
          path: `${APP_PREFIX_PATH}/plans/add`,
          title: 'ADD NEW PLAN',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
  
    {
      key: 'Plan Payments',
      path: `${APP_PREFIX_PATH}/plan-payments`,
      title: 'PLAN PAYMENTS',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: []
    },
  
    {
      key: 'plan-types',
      path: `${APP_PREFIX_PATH}/plan-types`,
      title: 'PLAN TYPES',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'plan-types-overview',
          path: `${APP_PREFIX_PATH}/plan-types/overview`,
          title: 'OVERVIEW',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'add-new-plan-type',
          path: `${APP_PREFIX_PATH}/plan-types/add`,
          title: 'ADD PLAN TYPE',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'plans-pending-verification',
      path: `${APP_PREFIX_PATH}/plans-pending-verification`,
      title: 'PLANS PENDING VERIFICATION',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'plans-pending-verification-individuals',
          path: `${APP_PREFIX_PATH}/plans-pending-verification/individual`,
          title: 'INDIVIDUAL PLANS',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'plans-pending-verification-family',
          path: `${APP_PREFIX_PATH}/plans-pending-verification/family`,
          title: 'FAMILY PLANS',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'plans-pending-verification-group',
          path: `${APP_PREFIX_PATH}/plans-pending-verification/group`,
          title: 'GROUP PLANS',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    
  /*
    {
      key: 'plan-covers',
      path: `${APP_PREFIX_PATH}/plan-covers`,
      title: 'plan covers',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'plan-covers-overview',
          path: `${APP_PREFIX_PATH}/plan-covers/overview`,
          title: 'overview',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'add-new-plan-cover',
          path: `${APP_PREFIX_PATH}/plan-covers/add`,
          title: 'add plan cover',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
  
    {
      key: 'cover-types',
      path: `${APP_PREFIX_PATH}/cover-types`,
      title: 'cover types',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'cover-types-overview',
          path: `${APP_PREFIX_PATH}/cover-types/overview`,
          title: 'overview',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'add-new-cover-types',
          path: `${APP_PREFIX_PATH}/cover-types/add`,
          title: 'add cover type',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
  */
    {
      key: 'facilities',
      path: `${APP_PREFIX_PATH}/facilities`,
      title: 'HEALTH FACILITIES',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'facilities-overview',
          path: `${APP_PREFIX_PATH}/facilities/overview`,
          title: 'OVERVIEW',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        },
  
        {
          key: 'add-new-facility',
          path: `${APP_PREFIX_PATH}/facilities/add`,
          title: 'ADD FACILITY',
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'Client Transactions',
      path: `${APP_PREFIX_PATH}/customer-transactions`,
      title: 'CUSTOMER TRANSACTIONS',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: []
    }
  
  ]
  
}

/*
const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'HOME',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'pricing',
    path: `${APP_PREFIX_PATH}/pricing`,
    title: 'PRICING',
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'USERS',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'customers',
				path: `${APP_PREFIX_PATH}/users/customers`,
				title: 'CUSTOMERS',
				icon: UserOutlined,
				breadcrumb: true,
				submenu: [
          {
            key: 'customers-overview',
            path: `${APP_PREFIX_PATH}/users/customers/overview`,
            title: 'OVERVIEW',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'add-customers',
            path: `${APP_PREFIX_PATH}/users/customers/add`,
            title: 'ADD CUSTOMER',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
      },
      
      {
        key: 'casemed-staff',
				path: `${APP_PREFIX_PATH}/users/casemed-staff`,
				title: 'CASEMED STAFF',
				icon: UserOutlined,
				breadcrumb: false,
				submenu: [
          {
            key: 'casemed-staff-overview',
            path: `${APP_PREFIX_PATH}/users/casemed-staff/overview`,
            title: 'OVERVIEW',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'add-casemed-staff',
            path: `${APP_PREFIX_PATH}/users/casemed-staff/add`,
            title: 'ADD CASEMED STAFF',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
      },

      {
        key: 'health-facility-staff',
				path: `${APP_PREFIX_PATH}/users/health-facility-staff`,
				title: 'HEALTH FACILITY STAFF',
				icon: UserOutlined,
				breadcrumb: true,
				submenu: [
          {
            key: 'health-facility-staff-overview',
            path: `${APP_PREFIX_PATH}/users/health-facility-staff/overview`,
            title: 'OVERVIEW',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'health-facility-staff-staff',
            path: `${APP_PREFIX_PATH}/users/health-facility-staff/add`,
            title: 'ADD HEALTH FACILITY STAFF',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
      },

      {
        key: 'service agents',
				path: `${APP_PREFIX_PATH}/users/service-agents`,
				title: 'SERVICE AGENTS',
				icon: UserOutlined,
				breadcrumb: true,
				submenu: [
          {
            key: 'service-agents-overvew',
            path: `${APP_PREFIX_PATH}/users/service-agents/overview`,
            title: 'OVERVIEW',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'add-service-agent',
            path: `${APP_PREFIX_PATH}/users/srvice-agents/add`,
            title: 'ADD SERVICE AGENT',
            icon: UserOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
      }
    ]
  },

  {
    key: 'plans',
		path: `${APP_PREFIX_PATH}/plans`,
		title: 'PLANS',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'plans-overview',
				path: `${APP_PREFIX_PATH}/plans/overview`,
				title: 'OVERVIEW',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'add-new-plan',
				path: `${APP_PREFIX_PATH}/plans/add`,
				title: 'ADD NEW PLAN',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },

  {
    key: 'Plan Payments',
		path: `${APP_PREFIX_PATH}/plan-payments`,
		title: 'PLAN PAYMENTS',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: []
  },

  {
    key: 'plan-types',
		path: `${APP_PREFIX_PATH}/plan-types`,
		title: 'PLAN TYPES',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'plan-types-overview',
				path: `${APP_PREFIX_PATH}/plan-types/overview`,
				title: 'OVERVIEW',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'add-new-plan-type',
				path: `${APP_PREFIX_PATH}/plan-types/add`,
				title: 'ADD PLAN TYPE',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },
  {
    key: 'plans-pending-verification',
		path: `${APP_PREFIX_PATH}/plans-pending-verification`,
		title: 'PLANS PENDING VERIFICATION',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'plans-pending-verification-individuals',
				path: `${APP_PREFIX_PATH}/plans-pending-verification/individual`,
				title: 'INDIVIDUAL PLANS',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'plans-pending-verification-family',
				path: `${APP_PREFIX_PATH}/plans-pending-verification/family`,
				title: 'FAMILY PLANS',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'plans-pending-verification-group',
				path: `${APP_PREFIX_PATH}/plans-pending-verification/group`,
				title: 'GROUP PLANS',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },
  
////////////////
  {
    key: 'plan-covers',
		path: `${APP_PREFIX_PATH}/plan-covers`,
		title: 'plan covers',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'plan-covers-overview',
				path: `${APP_PREFIX_PATH}/plan-covers/overview`,
				title: 'overview',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'add-new-plan-cover',
				path: `${APP_PREFIX_PATH}/plan-covers/add`,
				title: 'add plan cover',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },

  {
    key: 'cover-types',
		path: `${APP_PREFIX_PATH}/cover-types`,
		title: 'cover types',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'cover-types-overview',
				path: `${APP_PREFIX_PATH}/cover-types/overview`,
				title: 'overview',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'add-new-cover-types',
				path: `${APP_PREFIX_PATH}/cover-types/add`,
				title: 'add cover type',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },

  /////////////////////////////////////


  {
    key: 'facilities',
		path: `${APP_PREFIX_PATH}/facilities`,
		title: 'HEALTH FACILITIES',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: [
      {
        key: 'facilities-overview',
				path: `${APP_PREFIX_PATH}/facilities/overview`,
				title: 'OVERVIEW',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      },

      {
        key: 'add-new-facility',
				path: `${APP_PREFIX_PATH}/facilities/add`,
				title: 'ADD FACILITY',
				icon: IdcardOutlined,
				breadcrumb: true,
				submenu: []
      }
    ]
  },
  {
    key: 'Client Transactions',
		path: `${APP_PREFIX_PATH}/customer-transactions`,
		title: 'CUSTOMER TRANSACTIONS',
		icon: IdcardOutlined,
		breadcrumb: true,
		submenu: []
  }

]

*/


const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
